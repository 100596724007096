.photos {

  margin-top: 8rem;
  margin-bottom: 8rem;

  &__thumbnail {
    height: 200px;
    width: 200px;
    padding: 4px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    transition: transform 300ms ease-in-out;
    cursor: pointer;
    background-position: center;

    @media only screen and (max-width: 500px) {
      height: 40vw;
      width: 40vw;
    }

    &:hover {
      transform: scale(1.04);
    }
  }
}
