@import "src/variables";
@import "node_modules/bootstrap/scss/bootstrap-grid";

.gradient-color {

  @include media-breakpoint-up(sm) {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(135deg, $mainColor, $gradientColor);
    color: transparent;
  }

  color: $mainColor;
}

