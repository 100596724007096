@import "bootstrap/scss/bootstrap-utilities";
@import "bootstrap/scss/bootstrap-reboot";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/transitions";

@import "variables";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';

body {
  margin: 0;
  background-color: $backgroundColor;
  color: $textColor;
  font-family: 'Nunito', sans-serif;
}

a {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(45deg, $mainColor, $gradientColor);
  color: transparent;
  cursor: pointer;
}

.btn-outline-info {
  @include button-outline-variant($mainColor, #222222, lighten($mainColor, 5%), $mainColor);
}

.rounded-5 {
  border-radius: 1.5rem !important;
}

.resposive-shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; //mobile

  @include media-breakpoint-up(sm) {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; //desktop
  }

}
