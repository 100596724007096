@import "src/variables";
@import "node_modules/bootstrap/scss/bootstrap-grid";

.about {

  margin-bottom: 10rem !important;

  img {
    filter: grayscale(0.4);
    -webkit-transition: transform 300ms, -webkit-filter 300ms ease-in-out;

    &:hover {
      transform: scale(1.02);
      filter: grayscale(0.1);
    }
  }

  a {
    line-height: 2rem;
    color: $textColor;
    transition: transform 100ms ease-in-out;
    -webkit-text-fill-color: unset;
    -webkit-background-clip: unset;
    background-image: unset;

    &:hover {
      color: #01d0af;
      transform: scale(1.15);
    }

    &.xing:hover {
      @include media-breakpoint-up(sm) {
        background: linear-gradient(90deg, #0698a0 39%, #b7df4b 39%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      color: #0698A0;
    }

    &.linkedin:hover {
      color: #0a66c2;
    }

    &.facebook:hover {
      color: #1877f2;
    }

    &.instagram:hover {
      @include media-breakpoint-up(sm) {
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      color: #D6249F;
    }

    &.mail:hover {
      color: #ea3c3c;
    }
  }
}
