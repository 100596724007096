@import "src/variables";

.projects {

  .project {
    display: flex;
    flex-direction: column;
    transition: all 200ms ease-in-out;

    &:hover {
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    }

    &__collapsed {
      max-height: 0;
      overflow: hidden;
      transition: max-height 500ms ease-in-out;

      &--open {
        max-height: 9999px;
        transition: max-height 500ms ease-in-out;
      }
    }

    &__dropdown-button {
      cursor: pointer;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-image: linear-gradient(45deg, $mainColor, $gradientColor);
      color: transparent;
      transition: transform 300ms;

      &--open {
        transition: transform 300ms;
        transform: rotateX(180deg);
      }
    }

    &__image {
      cursor: pointer;
      width: 100px;
      padding: 4px;
      border: 1px solid #eeeeee;
      border-radius: 10px;
      transition: transform 300ms ease-in-out;

      &:hover {
        transform: scale(1.1);
      }

      &--square {
        width: 145px;
        height: 145px;
      }

      &--wide {
        width: 185px;
        height: 100px;
      }
    }
  }
}
