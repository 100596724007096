.impress {

  &__modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.80);
  }

  &__modal {

    position: fixed;
    background-color: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    height: 80vh;
    overflow: auto;
    padding: 1rem;
    border: 1px solid black;
  }

  &__modal-close {
    display: flex;
    justify-content: right;
    position: sticky;
    top: 5px;
    right: 0;
    cursor: pointer;
  }
}
